<template>
    <label class="secondary-font fw500 f12 secondary-1--text"> {{ text }} </label>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>